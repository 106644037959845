<template>

  <div class="wrapper">

    <section class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-light text-center mt-1 mb-3"> <span class="text-yellow">Login</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number and Password below
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-0">
            <input type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone number"
              name="mobile" v-model="msisdn">
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="input-group mb-0">
            <input :type="type" class="form-control" placeholder="XXXX" aria-label="Enter Password" name="password"
              v-model="password">
          </div>
          <small class="text-blue mb-3">Enter the 4 digit code sent to your phone</small>
          <input type="hidden" name="utm_source" value="">
          <input type="hidden" name="utm_medium" value="">
          <input type="hidden" name="utm_campaign" value="">
          <input type="hidden" name="referrer" value="">
        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By continuing for, you confirm that you are 18 years old or over and agree with the <a
          class="text-yellow">Terms, conditions and policies</a> of SparkPesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="login" class="join-button py-2 form-control">LOGIN</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Did you forget your Password? <br>
          No worries!
        </div>
        <a @click="setGetResetCode" class="login-button py-2 form-control">RESET</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Don't have an account? <br>
          Easy! Join SparkPesa <br>
        </div>
        <a @click="setSignup" class="login-button py-2 form-control">JOIN NOW</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-light text-center mt-1 mb-0">Join <span class="text-yellow">SparkPesa</span> </h3>
      <div class="text-light text-center mb-3">To Get Ksh.2000/= Gift</div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone Number"
              name="mobile" id="msisdn" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center m-auto">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img loading="lazy" src="img/other/password.svg" class="input-group-text-icon"></span>
            </div>   -->
            <input type="password" class="form-control" placeholder="Password" aria-label="Enter Password"
              name="password" id="signupPassword" v-model="password">
            <div class="input-group-append" @click="showPassword">
              <span class="input-group-text"><img loading="lazy" :src="btnText" class="input-group-text-icon"></span>
            </div>
          </div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img loading="lazy" src="img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="Confirm Password" aria-label="Confirm password"
              name="password_2" id="signupPassword1" v-model="password1">

            <div class="input-group-append" @click="showPassword">
              <span class="input-group-text"><img loading="lazy" :src="btnText" class="input-group-text-icon"></span>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Referral Code(If Any)" aria-label="Referral Code"
              name="referral_code" id="referral_code" v-model="referral_code" />
          </div>
        </form>
      </div>

      <div class="disclaimer text-center">
        By registering for SparkPesa, you confirm that you are 18 years old or over and agree with the <a
          class="text-yellow">Terms and Conditions and Privacy Policy</a> of SparkPesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="signup" class="join-button py-2 form-control">JOIN</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back <br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">LOGIN</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-light text-center mt-1 mb-3">Registration <span class="text-yellow">Code</span> </h3>
      <div class="text-center text-light mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <!--<div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Phone number 07 or 01" aria-label="Amount (to the nearest dollar)">
            <div class="input-group-append">
              <span class="input-group-text"></span>
            </div>
          </div>-->
          <div class="input-group mb-0">
            <div class="input-group-prepend">
              <span class="input-group-text"><img loading="lazy" src="img/other/password.svg"
                  class="input-group-text-icon"></span>
            </div>
            <input type="number" class="form-control" placeholder="Enter code" id="code" aria-label="Enter Code"
              v-model="code">
            <!--<div class="input-group-append">
              <span class="input-group-text"><img loading="lazy" src="img/other/eye.svg" class="input-group-text-icon"></span>
            </div>-->
          </div>
          <small class="text-blue mb-3">Enter the 4 digit code sent to your phone</small>

        </form>
      </div>

      <div class="disclaimer text-center">
        By activating yout SparkPesa account, you confirm that you are 18 years old or over and agree with the <a
          class="text-yellow">Terms, conditions and policies</a> of SparkPesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="verifyAccount" class="join-button py-2 form-control">Activate Account</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Didn’t get an SMS code?<br>
          Let’s try again in<br>
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">RESEND</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-light text-center mt-1 mb-3">Reset your <span class="text-yellow">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Enter your phone number
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="text" class="form-control" id="msisdn1" placeholder="Phone number 07 or 01"
              aria-label="Amount (to the nearest dollar)" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>

        </form>
      </div>

      <!--<div class="disclaimer text-center d-none">
        By cont for SparkPesa, you confirm that you are 18 years old or over and agree with the <a class="text-yellow">Terms, conditions and policies</a>  of SparkPesa
      </div>-->

      <div class="button-wrapper text-center mb-3">
        <a @click="getResetCode" class="join-button py-2 form-control">GET RESET CODE</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a href="#" class="login-button py-2 form-control">ENTER SMS CODE</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">LOGIN</a>
      </div>

    </section>

    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-light text-center mt-1 mb-3">Reset your <span class="text-yellow">Password</span> </h3>
      <div class="text-center text-light mb-3">
        Password reset code has been send to your mobile number. Enter the code you have received in your phone and your
        new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input type="text" class="form-control" placeholder="Enter code" id="code1" aria-label="Enter Code"
              v-model="code">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img loading="lazy" src="img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="New Password" aria-label="New Password"
              name="password" id="signupPassword3" v-model="password">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img loading="lazy" src="img/other/eye.svg" class="input-group-text-icon"></span>
            </div> -->
          </div>
          <div class="input-group mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img loading="lazy" src="img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input type="password" class="form-control" placeholder="Confirm New Password"
              aria-label="Confirm New Password" name="password_2" id="signupPassword4" v-model="password1">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img loading="lazy" src="img/other/eye.svg" class="input-group-text-icon"></span>
            </div> -->
          </div>


        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By cont for SparkPesa, you confirm that you are 18 years old or over and agree with the <a
          class="text-yellow">Terms, conditions and policies</a> of SparkPesa
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="resetPassword" class="join-button py-2 form-control">SET PASSWORD</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-light mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a href="#" class="login-button py-2 form-control">ENTER SMS CODE</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-light mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a @click="setLogin" class="login-button py-2 form-control">LOGIN</a>
      </div>

    </section>

  </div>

</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: .5em;
  height: 3em;
}
</style>

<script>
import axios from "@/services/identity";
import { mapState } from 'vuex';


export default {
  name: 'Login',
  components: {
  },
  comments: {

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        // react to route changes...
        let action = to.params.action;
        if (action === "login") {

          this.setLogin()
        }
      }
    },
  },

  data: function () {

    return {
      msisdn: '',
      referral_code: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: false,
      action_signup: true,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      btnText: '/img/other/eye.svg'
    }
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(['referralCode']),
  },
  methods: {
    login: function () {

      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");


      console.log('here we are')

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then(res => {

          vm.loading = '';

          var profile = res.data;
          var status = res.status;

          console.log("status " + status);

          if (parseInt(status) === 201 || parseInt(status) === 204) {

            // take password verification code
            vm.setVerifyAccount();
            return;

          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Success", "Login successful");
          vm.EventBus.$emit('init:mqtt');
          vm.goHome();


        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    signup: function () {
      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;
      }

      if (this.password.length < 4) {
        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;
      }

      if (this.password !== this.password1) {
        this.setError("Password Mismatch", "Your passwords do not match");
        return;
      }

      this.loading = 'loading';

      var vm = this;
      var path = "/signup";

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Referral Code:", this.referralCode);

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
        referral_code: this.referralCode,
        btag: btag
      }))
        .then(res => {
          vm.loading = '';

          const msg = res.data.data;
          var status = res.data;

          if (parseInt(status) === 202) {
            vm.setSuccess("Success", "Registration successful. Please login to proceed");
            vm.setLogin();
            return;
          }

          vm.setSuccess("Signup successful", msg);

          // Go to password verification page
          vm.setLogin();
        })
        .catch(err => {
          vm.loading = '';

          if (err.response) {
            vm.setError("Failed", err.response.data.error_message);
          } else if (err.request) {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err));
          }
        });
    },

    getResetCode: function () {

      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/password/forgot";

      axios.patch(path, JSON.stringify({
        msisdn: parseInt(this.msisdn)
      }))
        .then(res => {

          vm.loading = '';

          var status = res.status;

          if (parseInt(status) === 204) {

            // take password verification code
            vm.setVerifyAccount();
            return;

          }

          vm.setSuccess("Success", "If your phone number exist, you will receive a password reset code");
          // go to password reset page
          vm.setChangePassword();

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    changePassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/password/reset";

      axios.patch(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        old_password: this.code
      }))
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    resetPassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/password/reset";

      axios.patch(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(this.code)
      }))
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully");

          vm.loading = '';

          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.EventBus.$emit('init:mqtt');
          vm.goHome();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/verify";
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
      }))
        .then(res => {

          vm.loading = '';

          const profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          this.$router.push({ name: 'home', params: {} });

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    handleBlur: function () {
      if (this.msisdn.length < 9) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        document.getElementById('msisdn').classList.add("is-invalid")

      } else {
        document.getElementById('msisdn').classList.remove("is-invalid")
      }
    },
    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setResetPassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {

      if (document.getElementById('signupPassword').type == "password") {
        document.getElementById('signupPassword').type = "text"
        document.getElementById('signupPassword').style.color = "white"
      } else {
        document.getElementById('signupPassword').type = "password"
        document.getElementById('signupPassword').style.color = "rgba(255,255,255,.75)"
      }
      if (document.getElementById('signupPassword1').type == "password") {
        document.getElementById('signupPassword1').type = "text"
        document.getElementById('signupPassword1').style.color = "white"
      } else {
        document.getElementById('signupPassword1').type = "password"
        document.getElementById('signupPassword1').style.color = "rgba(255,255,255,.75)"
      }

      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/other/eyeslash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/other/eye.svg'
      }
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");

  }
}
</script>