<template>

  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Sparkpesa Deposit
        </strong>
      </div>
    </section>
    <div class="px-2">
      <section class="promo-card-wrapper mb-3">
        <div class=" text-yellow p-2" data-toggle="collapse" data-target="#1" aria-expanded="false" aria-controls="1">
          <strong>How to Deposit</strong>
        </div>
        <div class="promo-content text-light collapse p-2 show" id="1">
          <!--Some html static content-->
         <p>To start betting, you need to put money in your account using Mobile Money. Simply click on the Green tab on the top part of your device written “CLICK TO DEPOSIT FOR FREE". Two options are offered.</p>
         <p>1. Online deposit: <br>
Enter the amount you want to deposit.<br>
Click "Pay *", then enter your service pin into your phone to authorize the transaction.</p>
         <p>2. Paybill Option<br>
Go to Mpesa menu<br>
Select Payment services<br>
Click on Paybill<br>
Enter business number <br>
Enter the account number as SPARKPESA<br>
Enter the amount you want to transfer to Sparkpesa account<br>
Enter your Mpesa pin and Confirm the request<br>
You will  shortly receive an SMS from Mpesa to confirm the transaction.

         <p>
            DISCLAIMER:<br>
Sparkpesa accepts no responsibility should you make a deposit into any account other than that of the Company, or enter your own account details incorrectly when requesting a withdrawal. Your deposit is credited to the Sparkpesa username account matching the Mpesa mobile number used for making a deposit. It is your responsibility to ensure that you add your correct customer data as indicated on our site instructions and the correct Sparkpesa account or payment details. In the event that an error occurs, Sparkpesa accepts no responsibility for recovering these funds and your account will NOT be credited.
         </p>
         

         
        </div>
      </section>


    </div>

    <!-- <section class="social-links" style=" ">
       <div class="text-light width-available text-center">
        <strong>
          Frequently Asked Questions
        </strong>
      </div>
    </section> -->
  </div>

</template>

<script>

export default {
  name: 'DepositHowTo',
  components: {
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","deposit-how-to");
    this.reloadProfile();

  }

}
</script>